import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Nav from '../components/navigation'
import Footer from '../components/footer'
import Contact from '../components/contact'


class ServiceTemplate extends React.Component {

  render() {
    const host = 'https://www.reddigitalchina.com'
    const data = get(this.props, 'data.contentfulOurServices')
    const servicesNav = get(this.props,'data.allContentfulOurServices.edges')
    const heroImage = {
      background:`linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${data.image.file.url}")`,
      backgroundSize:'cover'
    }

    return (
      <div>
        <Helmet>
          <html lang="en"/>
          <title>Chinese {data.title} | Red Digital China</title>
          <meta name="description" content={data.seoDescription.childMarkdownRemark.rawMarkdownBody}/>
          <meta property="og:url" content={`${host}/services/${data.slug}`}/>
          <meta property="og:type" content="article"/>
          <meta property="og:local" content="en_GB"/>
          <meta property="og:title" content={data.title}/>
          <meta property="og:description" content={data.seoDescription.childMarkdownRemark.rawMarkdownBody}/>
          <meta property="og:site_name" content="Red Digital China: Chinese digital agency"/>
          <meta property="og:image" content={data.image.fluid.src}/>
          <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="79580515-4c86-4e39-aa84-3d661091d2e0" type="text/javascript" async></script>
        </Helmet>
        <section className="hero is-medium" style={heroImage}>
          <div className="hero-head">
            <Nav servicesNav={servicesNav}/>
          </div>
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title has-text-white is-capitalized is-size-1 is-size-3-mobile">chinese {data.title}</h1>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-8">
                  <div>
                        <div className="content"> 
                            <h1 className="title is-size-2 is-size-4-mobile">
                            Approach
                            </h1>
                            <hr className="title-underline"/>
                            <p className="content is-large is-size-5-mobile">{data.approach.childMarkdownRemark.rawMarkdownBody} </p>
                        </div>
                        <div className="content">
                            <h1 className="title is-size-2 is-size-4-mobile">
                            Services
                            </h1>
                            <hr className="title-underline"/>
                            <div className="content is-large is-size-5-mobile"
                                dangerouslySetInnerHTML={{
                                __html: data.services.childMarkdownRemark.html,
                                }}
                            />
                        </div>
                        {
                            data.platforms ? (
                                <div className="content">
                                    <h1 className="title is-size-2 is-size-4-mobile">
                                    Platforms
                                    </h1>
                                    <hr className="title-underline"/>
                                    <div className="content is-large is-size-5-mobile"
                                        dangerouslySetInnerHTML={{
                                        __html: data.platforms.childMarkdownRemark.html,
                                        }}
                                    />
                                </div>
                            ) : null
                        }
                        {
                            data.technology? (
                                <div className="content">
                                    <h1 className="title is-size-2 is-size-4-mobile">
                                    Technology
                                    </h1>
                                    <hr className="title-underline"/>
                                    <div className="content is-large is-size-5-mobile"
                                        dangerouslySetInnerHTML={{
                                        __html: data.technology.childMarkdownRemark.html,
                                        }}
                                    />
                                </div>
                               
                            ): null
                        }
                  </div>
              </div>
            </div>
          </div>        
        </section>
        <Contact/>
        <Footer/>
      </div>
    )
  }
}

export default ServiceTemplate

export const pageQuery = graphql`
  query ServiceBySlug($slug: String!) {
    allContentfulOurServices(filter: {node_locale: {eq: "en-GB"}}, sort: {order: ASC, fields: order}) {
        edges {
          node {
            title
            slug
          }
        }
      }  
    contentfulOurServices(slug: { eq: $slug }) {
        title
        slug
        image {
          fluid(quality: 100, resizingBehavior: FILL) {
            src
            }
            file {
            url
            }
        }
        featureImage{
          file{
            url
          }
          fluid(quality:100){
            src
          }
        }
        seoDescription{
            childMarkdownRemark{
              rawMarkdownBody
            }
        }
        approach {
            childMarkdownRemark {
                excerpt    
                rawMarkdownBody
            }
        }
        services {
            childMarkdownRemark {
                html
            }
        }
        platforms {
            childMarkdownRemark {
                html
            }
        }
        technology{
            childMarkdownRemark{
              html
            }
        }
    }
  }
`