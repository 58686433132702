import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

class Contact extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			messageSent: false
		}
		this.contactSchema = Yup.object().shape({
			name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required').matches(/[^0-9]/,'Numbers in the name?'),
			email: Yup.string().email('invalid email').required('Required!'),
			companyName: Yup.string().min(2, 'Too short!').required('Required!'),
			phoneNumber: Yup.number().required('Required').positive(),
			message: Yup.mixed().required('Required')
		})
	}

	render() {
		return(
		<section id="contact" className="section has-background-light">
			<div className="container">
				<h1 className="title has-text-danger has-text-centered is-size-1 is-size-2-mobil">Contact Us</h1>
				<Formik 
					initialValues={{
						to:'sarahyam%40reddigitalchina.com',
						cc:'stan.sun%40reddigitallab.com',
						name:'',
						email:'',
						companyName:'',
						phoneNumber:'',
						message:'',
					}}
					validationSchema={this.contactSchema}
					onSubmit={(values, {resetForm}) => {
						// same shape as initial values
						axios({
							method: 'post',
							url:'https://8dbp32h7ge.execute-api.eu-west-1.amazonaws.com/dev/?format=json',
							data: `_to=${values["to"]}&_cc=${values["cc"]}&_replyTo=${values["email"]}&name=${values["name"]}&email=${values["email"]}&company=${values["companyName"]}&phone=${values["phoneNumber"]}&message=${values["message"]}`,
						}).then((response) => {
							if (response.data.statusCode==200) {
								this.setState(prevState => ({
									messageSent: !prevState.messageSent
								}))
							}
							resetForm({
								to:'sarahyam%40reddigitalchina.com',
								cc:'sunqqxp%40gmail.com',
								name:'',
								email:'',
								companyName:'',
								phoneNumber:'',
								message:'',
							})
						}).catch((error) => {
							console.log(error)
						})
					}}
				>
					{({errors, touched}) => (
						<div className="columns is-centered">
							<div className="column is-7">
								<Form id="Red Digital China Contact Form">
									<div className="field is-horizontal">
										<div className="field-body">
											<div className="field">
												<div className="control has-text-centered">
													{this.state.messageSent ? (<p className="is-danger message-submitted">Thanks for your message we will get back to you within 3 business days</p>) : null}
												</div>
											</div>
										</div>
									</div>
									<div className="field is-horizontal">
										<div className="field-body">
											<div className="field">
												<p className="control is-expanded has-icons-left">
													<Field className="input is-medium" name="name" placeholder="Name*"/>
													<span className="icon is-small is-left">
														<i className="fas fa-user"></i>
													</span>
												</p>
												{errors.name && touched.name ? (<p className="help is-danger">{errors.name}</p>) : null}
											</div>
											<div className="field">
												<p className="control is-expanded has-icons-left">
													<Field className="input is-medium" name="email" type="email" placeholder="Email*"/>
													<span className="icon is-small is-left">
														<i className="fas fa-envelope"></i>
													</span>
												</p>
												{errors.email && touched.email ? (<p className="help is-danger">{errors.email}</p>) : null}
											</div>
										</div>
									</div>
									<div className="field is-horizontal">
										<div className="field-body">
											<div className="field">
												<p className="control is-expanded has-icons-left">
													<Field className="input is-medium" name="companyName" placeholder="Company Name*"/>
													<span className="icon is-small is-left">
														<i className="fas fa-building"></i>
													</span>
												</p>
												{errors.companyName && touched.companyName ? (<p className="help is-danger">{errors.companyName}</p>) : null}
											</div>
											<div className="field">
												<p className="control is-expanded has-icons-left">
													<Field className="input is-medium" name="phoneNumber"  placeholder="Phone Number*"/>
													<span className="icon is-small is-left">
														<i className="fas fa-phone-volume"></i>
													</span>
												</p>
												{errors.phoneNumber && touched.phoneNumber ? (<p className="help is-danger">Wrong phone number format, please enter number only</p>) : null}
											</div>
										</div>
									</div>
									<div className="field is-horizontal">
										<div className="field-body">
											<div className="field">
												<div className="control">
													<Field className="textarea is-medium" placeholder="Drop your message here*" component="textarea" name="message" rows="8"/>	
												</div>
												{errors.message && touched.message ? (<p className="help is-danger">{errors.message}</p>) : null}
											</div>
										</div>
									</div>
									<div className="field is-horizontal">
										<div className="field-body">
											<div className="field">
												<div className="control has-text-centered">
													<button className="button is-danger is-medium" type="submit">Send message</button>
												</div>
											</div>
										</div>
									</div>
								</Form>
							</div>
						</div>
					)}	
				</Formik>

			</div>
		</section>
	)}
}

export default Contact